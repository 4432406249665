import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const YouTubeCarousel = (parameters) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: false,
    centerPadding: '250px',
    arrows: true
  };

  // Static array of YouTube video links
  const videos = {
    "Halloween 2024": [
      "https://www.youtube.com/embed/bhsShXUbUJA",
      "https://www.youtube.com/embed/bhsShXUbUJA",
      // Add more video links as needed
    ],
    "Christmas 2023": [
      "https://www.youtube.com/embed/bhsShXUbUJA",
      "https://www.youtube.com/embed/bhsShXUbUJA"
      // Add more video links as needed
    ]
  };

  return (
    <div>
      <br/>
        <h2>{parameters.event}</h2>
        <Slider>
          {videos[parameters.event].map((videoLink, index) => (
            <div key={index}>
              <iframe
                width="100%"
                height="500px%"
                src={videoLink.replace('watch?v=', 'embed/')}
                title={`Show ${index + 1}`}
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </Slider>
      </div>
  );
};

const WatchPage = ({ }) => {
  return (
    
    <div style={{ paddingTop: '50px', paddingBottom: '50px', paddingLeft: '100px', paddingRight: '100px'}}>
        <h1>Previous Shows</h1>
        <YouTubeCarousel event="Halloween 2024"></YouTubeCarousel>
        <YouTubeCarousel event="Christmas 2023"></YouTubeCarousel>
    </div>
  );
};

export default WatchPage;
