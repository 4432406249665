import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap styles
import './RequestsPage.css';
import './EntranceAnimations.css';
import './StatusAnimations.css';
import './TextAnimations.css';
import './ChristmasThemeAnimations.scss';
import './HalloweenThemeAnimations.scss';
import { Container, Row, Col, ListGroup, ProgressBar, Image } from 'react-bootstrap'; // Import Bootstrap components
import axios from 'axios';
import RequestButton from './RequestButton'; // Import NavigationBar component
import AudioPlayer from './AudioPlayer';

const RequestsPage = () => {
  const API_URL = "http://119.18.38.20:81"//"http://192.168.1.100:81"//"http://192.168.1.101:8100" //"http://localhost:8100"//"http://192.168.1.101:81" or "http://localhost:81"

  const [serverState, setServerState] = useState(null)
  const [queue, setQueue] = useState([])
  const [sequences, setSequences] = useState([])

  useEffect(() => {
    // Function to fetch now playing data from the API
    const fetchServerState = () => {
      axios.get(API_URL + '/status')
        .then(response => {
          setServerState(response.data);
          setQueue(response.data.queue)
          setSequences(response.data.show.sequences)
        })
        .catch(error => {
          console.error('Error fetching status:', error);
        });
    };

    // Fetch now playing data initially and every X seconds
    fetchServerState();
    const intervalServerState = setInterval(fetchServerState, 1000);

    // Cleanup functions to clear intervals
    return () => {
      clearInterval(intervalServerState);
    };
  }, []);

  const hexToRgba = (hex, alpha) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  const isShowActive = () => {
    if(!serverState)
      return false;
    return serverState.show.active
  }

  const getNextShowTimeText = () => {
    const differenceInMilliseconds = Math.abs(new Date(serverState.show.schedule) - Date.now());
    const seconds = Math.floor(differenceInMilliseconds / 1000);
    if (seconds < 60) {
        return seconds < 0 ? 0 : seconds + (seconds === 1 ? ' second' : ' seconds');
    } else if (seconds < 3600) {
        const minutes = Math.floor(seconds / 60);
        return minutes + (minutes === 1 ? ' minute' : ' minutes');
    } else if (seconds < 86400) {
        const hours = Math.floor(seconds / 3600);
        return hours + (hours === 1 ? ' hour' : ' hours');
    } else {
        const days = Math.floor(seconds / 86400);
        return days + (days === 1 ? ' day' : ' days');
    }
  }

  return (
    <div>
      <Container className="mt-5">
        {/* Now Playing */}
        <Row className="mt-5">
          <Col>
          {isShowActive() && (
          <h2>Now Playing</h2>
          )}
          
          {!isShowActive() && (
            <div style={{ marginTop: '100px', marginBottom: '100px', textAlign: 'center'}}>
              <h1 className="tracking-in-expand">Thanks for watching!</h1>
                {serverState?.show?.theme && serverState?.show?.schedule != null && (
                    <h4 className="tracking-in-expand">{serverState.show.theme} show starts in {getNextShowTimeText()}</h4>
                )}
                
              <br/>
              <p>While you wait, watch our previous shows <a href="/watch">here</a></p>
            </div>
          )}
            <ListGroup>
                {serverState?.show?.theme === "Christmas" && (
                  <div className="snow"/>
                )}

                {isShowActive() && (
                  <ListGroup.Item key="now-playing" className="sequence-item" style={{ opacity: 1, background: `linear-gradient(90deg, rgba(20,20,20,1), ${hexToRgba(serverState.sequence.tagColours[0] ?? "#555555", 0.6)})`}}>
                    <div className="sequence-details">
                      <Row className="align-items-center">
                        <Col xs={3} className='sequence-artwork-column-playing'>
                          <div className="sequence-artwork-playing">
                            <Image src={`${API_URL}/sequences/${serverState.sequence.title}/art`} alt={serverState.sequence.title} fluid />
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <span className="sequence-title">{serverState.sequence.title}</span><br style={{height: '5px'}}/><span className="sequence-artist">{serverState.sequence.artist}</span>
                          </div>
                          <div className="sequence-tags">
                            {serverState.sequence.tags && serverState.sequence.tags.length > 0 && serverState.sequence.tagColours &&
                              serverState.sequence.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className="tag" style={{ backgroundColor: serverState.sequence.tagColours[tagIndex] ?? "#555555" }}>{tag}</span>
                              ))}
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <div className="sequence-requester">
                            <span>{ serverState.sequence.requester != null ? "Requested by": ""}</span>
                            <br/>
                            <span>{ serverState.sequence.requester != null ? serverState.sequence.requester : ""}</span>
                          </div>
                          <AudioPlayer time={serverState.sequence.elapsed} />
                        </Col>
                      <div>
                        <ProgressBar variant="progress" className="playing-progress-bar" now={(serverState.sequence.elapsed / (serverState.sequence.remaining + serverState.sequence.elapsed)) * 100} style={{ background: `linear-gradient(90deg, ${hexToRgba(serverState.sequence.tagColours[0] ?? "#555555", 0.6)}, ${hexToRgba(serverState.sequence.tagColours[1] ?? "#222222", 0.6)})`}} />
                      </div>

                      </Row>
                    </div>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Col>
        </Row>

        {/* Request Queue */}
        { queue.filter(x => x.show).length > 0 && (
          <Row className="mt-5">
            <Col>
              <h2 className="tracking-in-expand">Up Next</h2>
              <ListGroup>
                {queue.map((item, index) => (
                  item.show &&  // filter out queue items that are not intended to be shown
                  <ListGroup.Item key={index} className="sequence-item bounce-right" style={{ background: `linear-gradient(90deg, rgba(20,20,20,1), ${hexToRgba(item.sequence.tagColours ? item.sequence.tagColours[0] : "#555555", 0.6)})`}}>
                    <div className="sequence-details">
                      <Row className="align-items-center">
                        <Col xs={3} className='sequence-artwork-column'>
                          <div className="sequence-artwork">
                            <Image src={`${API_URL}/sequences/${item.sequence.title}/art`} alt={item.sequence.title} fluid />
                          </div>
                        </Col>
                        <Col>
                          <div>
                            <span className="sequence-title">{item.sequence.title}</span><br/><span className="sequence-artist">{item.sequence.artist}</span>
                          </div>
                          <div className="sequence-tags">
                            {item.sequence.tags && item.sequence.tags.length > 0 && item.sequence.tagColours &&
                              item.sequence.tags.map((tag, tagIndex) => (
                                <span key={tagIndex} className="tag" style={{ backgroundColor: item.sequence.tagColours[tagIndex] ?? "#555555" }}>{tag}</span>
                              ))}
                          </div>
                        </Col>
                        <Col className="d-flex justify-content-end">
                          <div className="sequence-requester">
                            <span>{ item.requester != null ? "Requested by": ""}</span>
                            <br/>
                            <span>{ item.requester != null ? item.requester : ""}</span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        )}

        {/* Available Sequences */}
        <Row className="mt-5">
          <Col>
          {isShowActive() && (
          <h2>Request a Track</h2>
          )}
          {!isShowActive() && sequences.length > 0 && (
            <div>
              <h2>{serverState?.show?.theme} Show Tracklist</h2>
            </div>
          )}
            <ListGroup>
              {sequences.map((sequence, index) => (
                <ListGroup.Item key={index} className="sequence-item" style={{ background: `linear-gradient(90deg, rgba(20,20,20,1), ${hexToRgba(sequence.tagColours[0] ?? "#555555", 0.6)})`}}>
                  <div className="sequence-details">
                    <Row className="align-items-center">
                      <Col xs={3} className='sequence-artwork-column'>
                        <div className="sequence-artwork">
                          <Image src={`${API_URL}/sequences/${sequence.title}/art`} alt={sequence.title} fluid />
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <span className="sequence-title">{sequence.title}</span><br/><span className="sequence-artist">{sequence.artist}</span>
                        </div>
                        <div className="sequence-tags">
                          {sequence.tags && sequence.tags.length > 0 && sequence.tagColours &&
                            sequence.tags.map((tag, tagIndex) => (
                              <span key={tagIndex} className="tag" style={{ backgroundColor: sequence.tagColours[tagIndex] ?? "#555555" }}>{tag}</span>
                            ))}
                        </div>
                      </Col>
                      {isShowActive() && (
                        <Col className="d-flex justify-content-end">
                          <RequestButton sequence={sequence} />
                        </Col>
                      )}
                    </Row>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RequestsPage;
