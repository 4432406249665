import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './Background.css';
import RequestsPage from './components/RequestsPage';
import FeedbackPage from './components/FeedbackPage';
import NavigationBar from './components/NavigationBar'; // Import NavigationBar component
import WatchPage from './components/WatchPage';

function App() {
  useEffect(() => {
    document.title = "Lil' Mac Lights"; // Set the title of the document
  }, []); // This effect runs only once after the component mounts

  return (
    <div className="page-background animated-gradient">
      <Router>
        <NavigationBar /> {/* NavigationBar added here */}

        <Routes>
          <Route path="/" element={<RequestsPage />} />
          <Route path="/feedback" element={<FeedbackPage />} />
          <Route path="/watch" element={<WatchPage />} />
          {/* Add more routes here if you have other pages */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
