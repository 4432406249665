import React, { useRef, useState, useEffect } from 'react';
import './RequestsPage.css'; // Import custom CSS file for styling

const AudioPlayer = ({ time }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(time ?? 0); // Initialize with the provided time

  useEffect(() => {
    setCurrentTime(time); // Update currentTime when the time prop changes
  }, [time]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.currentTime = currentTime; // Set to the currentTime before playing
      audioRef.current.play().catch((error) => {
        console.error('Error trying to play audio:', error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <audio ref={audioRef} src="http://localhost:8100/media" preload="auto" />
      <button onClick={togglePlayPause} className="rounded-button" size="sm">
        {isPlaying ? 'Listening...' : 'Tune In'}
      </button>
    </div>
  );
};

export default AudioPlayer;
