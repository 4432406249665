import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './TextAnimations.css';
import './FeedbackPage.css';

const FeedbackForm = ({ onSubmit }) => {
  const [feedback, setFeedback] = useState({
    name: '',
    rating: 0,
    comments: ''
  });

  const handleFeedbackChange = (event) => {
    const { name, value } = event.target;
    setFeedback({
      ...feedback,
      [name]: value
    });
  };

  return (
    <div class="flex-table">
      <div class="flex-column" style={{  paddingLeft: '100px', paddingTop: '100px', paddingBottom: '100px' }}>
        <h2>Feedback</h2>
        <p>We hope you have enjoyed the show and welcome any feedback you may have. Complaints are best lodged elsewhere as explained <a href="#complaints">below.</a></p>
        
        <Form onSubmit={(event) => onSubmit(event, feedback)}>
          <Form.Group controlId="name">
            <Form.Label style={{ color: 'white'}}>Your Name</Form.Label>
            <Form.Control type="text" name="name" value={feedback.name} onChange={handleFeedbackChange} required />
          </Form.Group>
          <Form.Group controlId="rating">
            <Form.Label style={{ marginTop: '15px', marginBottom: '-10px', color: 'white' }}>Rating</Form.Label>
            <div>
              {[...Array(5)].map((_, index) => (
                <span key={index} style={{ cursor: 'pointer', color: index < feedback.rating ? 'yellow' : 'gray' }} onClick={() => setFeedback({ ...feedback, rating: index + 1 })}>
                  ★
                </span>
              ))}
            </div>
          </Form.Group>
          <Form.Group controlId="comments">
            <Form.Label style={{ marginTop: '10px', color: 'white' }}>Comments</Form.Label>
            <Form.Control as="textarea" rows={3} name="comments" value={feedback.comments} onChange={handleFeedbackChange} required />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">Submit</Button>
        </Form>
      </div>
      <div class="flex-column" style={{  paddingRight: '100px', paddingTop: '100px', paddingBottom: '100px' }}>
        <section id="complaints"></section>
        <h2>Noise & Brightness Complaints</h2>
        <p>The comfort of neighbours during our shows are always top of mind. Our shows are intended only to be enjoyed and we try our best to keep it that way.</p>
        <p>Show times are strictly limited to reasonable hours with potential extensions on weekends within festive periods.
          To combat noise-related disturbances, music volume is monitored closely, meticulously tuned to remain consistent, and progressively lowered into the night.</p>
        <p>If you are finding the show disturbing due to light brightness or music, we respectfully request that complaints be raised in person, on social media, or via a letter in our postbox. This better allows us to validate concerns and best action a targeted strategy for rectification.</p>
      </div>
    </div>
  );
};

export default FeedbackForm;
