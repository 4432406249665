import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import './RequestsPage.css'; // Import custom CSS file for styling

const RequestButton = ({ sequence }) => {
  const API_URL = "http://localhost:8100"

  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState('');
  const [anonymous, setAnonymous] = useState(true); // Default to true
  const [errorMessage, setErrorMessage] = useState('');

  const handleClose = () => {
    setShowModal(false);
    setErrorMessage(''); // Clear error message when modal is closed
  };

  const handleShow = () => setShowModal(true);

  const handleSubmit = () => {
    const requester = anonymous ? 'Anonymous' : userName.trim();
    // Make the request to add the sequence to the queue
    axios.post(`${API_URL}/queue/${sequence.title}?requester=${requester}`)
      .then(response => {
        console.log('Sequence added to queue:', response.data);
        setShowModal(false);
        setErrorMessage(''); // Clear error message on successful request
        // Optionally, you can show a success message or update the UI accordingly
      })
      .catch(error => {
        console.log(error)
        console.error('Error adding sequence to queue:', error);
        if (error.response) {
          setErrorMessage(error.response.data);
        } else {
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      });
  };

  const handleAnonymousChange = (e) => {
    setAnonymous(e.target.checked);
    // If anonymous is checked, clear the username
    if (e.target.checked) {
      setUserName('');
    }
  };

  return (
    <>
      <Button variant="primary" className="rounded-button" size="sm" onClick={handleShow}>Request</Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check
              type="switch"
              id="anonymous-switch"
              label="Anonymous"
              checked={anonymous}
              onChange={handleAnonymousChange}
              className="mb-2"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              type="text"
              placeholder="Your Name"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              disabled={anonymous}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSubmit} disabled={!anonymous && !userName.trim()}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestButton;
